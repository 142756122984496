//export const HOST = 'https://apimv.ticservice.org:17100/';
export const HOST = process.env.REACT_APP_API_URL;
export const VERSION = 'api/v1/';

const APP_URL = {
    // AUTH
    LOGIN: `${HOST}${VERSION}auth/login/`,

    // ADMIN - APPOINTMENT
    SEARCH_PATIENTS_APPOINTMENTS: `${HOST}${VERSION}admin/appointment/searchPatient`,
    UPLOAD_EVIDENCES: `${HOST}${VERSION}admin/appointment/uploadFiles`,
    CREATE_APPOINTMENT: `${HOST}${VERSION}admin/appointment/create`,
    LIST_APPOINTMENTS: `${HOST}${VERSION}admin/appointment/list`,
    CHANGE_STATUS_APPOINTMENT: `${HOST}${VERSION}admin/appointment/updateStatus`,
    LIST_FILE_APPOINTMENTS: `${HOST}${VERSION}admin/appointment/listFiles`,
    LIST_APP_STATUS_USER: `${HOST}${VERSION}admin/appointment/listAppStatusUser`,
    COUNT_APPOINTMENT_TOTAL_DAY_MONTH: `${HOST}${VERSION}admin/appointment/CountAppointmentTotalDayMonth`,
    COUNT_APP_STATUS: `${HOST}${VERSION}admin/appointment/countAppStatusUser`,
    LIST_TRACKER: `${HOST}${VERSION}admin/appointment/listTracker`,

    // ADMIN - APPOINTMENT - REFFERED
    CREATE_REFFERED: `${HOST}${VERSION}admin/appointment/createReffered`,
    LIST_REFFERED_ALL: `${HOST}${VERSION}admin/appointment/listRefferedAll`,
    LIST_REFFERED_AGENT: `${HOST}${VERSION}admin/appointment/listReffered`,
    UPDATE_REFFERED: `${HOST}${VERSION}admin/appointment/updateReffered`,
    CREATE_TRACKER: `${HOST}${VERSION}admin/appointment/createTracker`,
    EDIT_DATA_USER: `${HOST}${VERSION}admin/appointment/edit`,

    // ADMIN - PARAMETER
    LOAD_INSURANCE: `${HOST}${VERSION}admin/parameter/list`,

    // CREATE FORM OBAMA CARE
    CREATE_FORM_OBAMA_CARE: `${HOST}${VERSION}admin/obamacare/create`,

    // PARAMETER
    LOAD_PARAMETER: `${HOST}${VERSION}admin/parameter/list/`,
    LIST_EMAIL_MODULE: `${HOST}${VERSION}admin/parameter/listEmailModule/`,

    // LIST STATUS
    LIST_STATUS: `${HOST}${VERSION}admin/obamacare/listStatusTracker`,
    LIST_STATUS_SUPERVISOR: `${HOST}${VERSION}admin/obamacare/listStatusTrackersupervisor`,

    // SEARCH PATIENTS
    SEARCH_PATIENTS: `${HOST}${VERSION}admin/patients/search`,

    // Status Follow-up
    LIST_TRACKING: `${HOST}${VERSION}admin/obamacare/listTracker`,
    CREATE_TRACKING: `${HOST}${VERSION}admin/obamacare/createTracker`,
    UPDATE_TRACKING: `${HOST}${VERSION}admin/obamacare/updateTracker`,
    UPDATE_FORMOBAMACARE: `${HOST}${VERSION}admin/obamacare/editform`,

    // Upload
    UPLOADEXCELFILE: `${HOST}${VERSION}admin/upload/uploadexcel`,
    UPLOADEXCELAGENTSBETWEEN: `${HOST}${VERSION}admin/upload/uploadexcelAgentsBetween`,
    UPLOADPAY: `${HOST}${VERSION}admin/pay/uploadPay`,
    UPLOADNUMBERRENEW: `${HOST}${VERSION}admin/upload/uploadNumberRenew`,
    LISTPHONEAGENTALL: `${HOST}${VERSION}admin/upload/listPhoneAgentAll`,
    LISTPHONEAGENTALLSUPERVISOR: `${HOST}${VERSION}admin/upload/listPhoneAgentAllSupervisor`,
    LISTAGENTALL: `${HOST}${VERSION}admin/upload/listAgentAll`,
    LISTALLUSER: `${HOST}${VERSION}admin/upload/listAllUser`,
    UPDATESTATUS: `${HOST}${VERSION}admin/upload/updateStatus`,
    UPDATESTATUSANDAGENT: `${HOST}${VERSION}admin/upload/updatestatusAndAgent`,

    LISTAGENTALLFILTER: `${HOST}${VERSION}admin/upload/listAgentAllFilter`,
    LISTAGENTFILTER: `${HOST}${VERSION}admin/upload/listAgentFilter`,
    LISTAGENTFILTERSTATUS: `${HOST}${VERSION}admin/upload/listAgentFilterStatus`,

    // USERS
    LISTUSERS: `${HOST}${VERSION}admin/users/list`,
    LISTUSERSALL: `${HOST}${VERSION}admin/users/listAll`,
    ADDAGENTS: `${HOST}${VERSION}admin/users/AddAgents`,
    ADDNPN: `${HOST}${VERSION}admin/users/AddNPN`,
    SEARCHAGENTS: `${HOST}${VERSION}admin/users/searchAgents`,
    SEARCHNPN: `${HOST}${VERSION}admin/users/searchNPN`,
    LISTNPN: `${HOST}${VERSION}admin/obamacare/listNPN`,
    CREATEUSERS: `${HOST}${VERSION}admin/users/create`,
    UPDATEUSERS: `${HOST}${VERSION}admin/users/update`,
    UPDATESTATUSUSERS: `${HOST}${VERSION}admin/users/updateStatus`,
    UPDATEPASSUSERS: `${HOST}${VERSION}admin/users/updatePass`,
    LISTAGENT: `${HOST}${VERSION}admin/users/listAgent`,
    LISTAGENTON: `${HOST}${VERSION}admin/users/listAgentON`,
    LISTAGENTONSUPERVISOR: `${HOST}${VERSION}admin/users/listAgentONSupervisor`,
    LISTAGENTPROFILE: `${HOST}${VERSION}admin/users/listAgentProfile`,
    REPORTLOGIN: `${HOST}${VERSION}admin/users/reportLogin`,

    // CRUD AGENT
    CREATEASSIGNEDAGENT: `${HOST}${VERSION}admin/assignedagent/create`,
    UPDATEASSIGNEDAGENT: `${HOST}${VERSION}admin/assignedagent/update`,
    UPDATESTATUSAGENT: `${HOST}${VERSION}admin/assignedagent/updatestatus`,
    LISTASSIGNEDAGENT: `${HOST}${VERSION}admin/assignedagent/list`,

    // CREATE LIST PHONE CARE CROSS
    CREATELISTCARECROSS: `${HOST}${VERSION}admin/carecross/createlistphone`,
    LISTPHONECARECROSS: `${HOST}${VERSION}admin/carecross/listphone`,

    // REPORTS STATUS PHONE 
    REPORTSTATUSPHONE: `${HOST}${VERSION}admin/obamacare/reports`,
    REPORTSTATUSPHONENOANSWERAGENT: `${HOST}${VERSION}admin/obamacare/reportnumbernoansweragent`,
    DELETEAPPOINTMENT: `${HOST}${VERSION}admin/obamacare/deleteAppointment`,

    // UPDATE STATUS PHONE AGENT
    UPDATESTATUSPHONEAGENT: `${HOST}${VERSION}admin/obamacare/updatestatusphoneagent`,

    // APPOINTMENT SALES
    CREATESALES: `${HOST}${VERSION}admin/obamacare/createsales`,
    CREATESALESPENDING: `${HOST}${VERSION}admin/obamacare/createsalespending`,
    CREATESALESDRAFTAGENT: `${HOST}${VERSION}admin/obamacare/createsalesDraftAgent`,
    UPDATESALES: `${HOST}${VERSION}admin/obamacare/updatesales`,
    UPDATESALESQUALITY: `${HOST}${VERSION}admin/obamacare/updatesalesQuality`,
    UPDATESALESPROCESS: `${HOST}${VERSION}admin/obamacare/updatesalesProcess`,
    UPDATESALESAGENT: `${HOST}${VERSION}admin/obamacare/updatesalesAgent`,
    UPDATESALESDATA: `${HOST}${VERSION}admin/obamacare/updatesalesData`,
    UPDATESALESDATARENEW: `${HOST}${VERSION}admin/obamacare/updatesalesDataRenew`,
    UPDATESALESDATARECOVERY: `${HOST}${VERSION}admin/obamacare/updatesalesDataRecovery`,
    UPDATESALESFILE: `${HOST}${VERSION}admin/obamacare/updateSalesFile`,
    UPDATESALESVERIFYBACKOFFICE: `${HOST}${VERSION}admin/obamacare/updateSalesVerifyBackOffice`,
    UPDATEUPLOADSALESDOCUMENT: `${HOST}${VERSION}admin/obamacare/updateUploadSalesDocument`,
    UPDATESTATUSFINISH: `${HOST}${VERSION}admin/obamacare/updateStatusFinish`,
    UPDATESTATUSFINISHNPN: `${HOST}${VERSION}admin/obamacare/updateStatusFinishNPN`,
    UPDATESTATUSFINISHNPNCANCELLED: `${HOST}${VERSION}admin/obamacare/updateStatusFinishNPNCancelled`,
    MANAGEMENTOBSERVATION: `${HOST}${VERSION}admin/obamacare/managementObservation`,
    CREATEAUDIT: `${HOST}${VERSION}admin/audit/createAudit`,
    UPDATENPN: `${HOST}${VERSION}admin/obamacare/updateNPN`,
    UPDATESHERPA: `${HOST}${VERSION}admin/obamacare/updateSherpa`,
    SEARCHSALESPRE: `${HOST}${VERSION}admin/obamacare/searchsalespre`,
    SEARCHSALESPRECONSULTOR: `${HOST}${VERSION}admin/obamacare/searchsalespreConsultor`,
    SEARCHSALESPRENEW: `${HOST}${VERSION}admin/obamacare/searchsalesprenew`,
    LISTSALESSUPERVISORPRENEW: `${HOST}${VERSION}admin/obamacare/listSalesSupervisorPrenew`,
    SEARCHSALESPRENEWRECOVERY: `${HOST}${VERSION}admin/obamacare/searchsalespreNewRecovery`,
    SEARCHSALESPRENEWTRANSFERUSERID: `${HOST}${VERSION}admin/obamacare/searchsalesprenewTransferUserID`,
    SEARCHSALESPRENEWVERIFY: `${HOST}${VERSION}admin/obamacare/searchsalespreNewVerify`,
    LISTSALESADMINAGENTS: `${HOST}${VERSION}admin/obamacare/listSalesAdminAgents`,
    LISTSALESADMINAGENTSTATUSFINISH: `${HOST}${VERSION}admin/obamacare/listSalesAdminAgentsStatusFinish`,
    SEARCHSALESDEPENDENT: `${HOST}${VERSION}admin/obamacare/searchsalesdepent`,
    SEARCHSALESPLAN: `${HOST}${VERSION}admin/obamacare/searchsalesplan`,
    SEARCHSALESBENEFICIARIES: `${HOST}${VERSION}admin/obamacare/searchsalesbeneficiaries`,
    SEARCHUPLOADSALESDOCUMENT: `${HOST}${VERSION}admin/obamacare/searchuploadsalesdocument`,
    DELETEUPLOADSALES: `${HOST}${VERSION}admin/obamacare/deleteuploadsales`,
    SEARCHSALESAGENTS: `${HOST}${VERSION}admin/obamacare/searchsalesagents`,
    SEARCHSALESAGENTSRENOVATION: `${HOST}${VERSION}admin/obamacare/searchsalesagentsRenovation`,
    SEARCHSALESPROCESSOR: `${HOST}${VERSION}admin/obamacare/searchsalesprocessor`,
    SEARCHSALESPROCESSORNOSS: `${HOST}${VERSION}admin/obamacare/searchsalesprocessorNoSS`,
    SEARCHSALESPROCESSORSS: `${HOST}${VERSION}admin/obamacare/searchsalesprocessorSS`,
    SEARCHSALESCUSTOMERMONTH: `${HOST}${VERSION}admin/obamacare/searchsalesCustomerMonth`,
    SEARCHSALESPROCESSORBO: `${HOST}${VERSION}admin/obamacare/searchsalesprocessorBO`,
    SEARCHSALESPROCESSORRECOVERY: `${HOST}${VERSION}admin/obamacare/searchsalesprocessorRecovery`,
    SEARCHSALESOBSERVATION: `${HOST}${VERSION}admin/obamacare/searchsalesobservation`,
    TOTALSALES: `${HOST}${VERSION}admin/obamacare/totalSales`,
    TOTALSALESACTIVE: `${HOST}${VERSION}admin/obamacare/totalSalesActive`,
    TOTALSALESACTIVEAGENT: `${HOST}${VERSION}admin/obamacare/totalSalesActiveAgent`,
    TOTALACTIVEMEMBERS: `${HOST}${VERSION}admin/obamacare/totalActiveMembers`,
    TOTALSALESOTHERPARTY: `${HOST}${VERSION}admin/obamacare/totalSalesOtherParty`,
    TOTALSALESNPN: `${HOST}${VERSION}admin/obamacare/totalSalesNPN`,
    TOTALSALESSUPERVISOR: `${HOST}${VERSION}admin/obamacare/totalSalesSupervisor`,
    TOTALSALESRENEW: `${HOST}${VERSION}admin/obamacare/totalSalesRenew`,
    TOTALSALESRENEWSUPERVISOR: `${HOST}${VERSION}admin/obamacare/totalSalesRenewSupervisor`,
    TOTALSALESLASTDAYS: `${HOST}${VERSION}admin/obamacare/totalSalesLastDays`,
    TOTALSALESLASTDAYSSUPERVISOR: `${HOST}${VERSION}admin/obamacare/totalSalesLastDaysSupervisor`,
    TOTALSALESAGENTS: `${HOST}${VERSION}admin/obamacare/totalSalesAgents`,
    TOTALSALESAGENTSSUPERVISOR: `${HOST}${VERSION}admin/obamacare/totalSalesAgentsSupervisor`,
    TOTALSALESAGENTSPLANDAY: `${HOST}${VERSION}admin/obamacare/totalSalesAgentsPlanDay`,
    TOTALSALESAGENTSPLANDAYSUPERVISOR: `${HOST}${VERSION}admin/obamacare/totalSalesAgentsPlanDaySupervisor`,
    TOTALSALESAGENTSPLANMONTH: `${HOST}${VERSION}admin/obamacare/totalSalesAgentsPlanMonth`,
    TOTALSALESAGENTSPLANMONTHSUPERVISOR: `${HOST}${VERSION}admin/obamacare/totalSalesAgentsPlanMonthSupervisor`,

    TOTALSALESTRANSFER: `${HOST}${VERSION}admin/obamacare/totalSalesTransfer`,

    TOTALSALESCOMPANYDAY: `${HOST}${VERSION}admin/obamacare/totalSalesCompanyDay`,
    TOTALSALESCOMPANYMONTH: `${HOST}${VERSION}admin/obamacare/totalSalesCompanyMonth`,
    TOTALSALESCOMPANYYEAR: `${HOST}${VERSION}admin/obamacare/totalSalesCompanyYear`,

    TOTALSALESCOMPANYDAYSUPERVISOR: `${HOST}${VERSION}admin/obamacare/totalSalesCompanyDaySupervisor`,
    TOTALSALESCOMPANYMONTHSUPERVISOR: `${HOST}${VERSION}admin/obamacare/totalSalesCompanyMonthSupervisor`,
    TOTALSALESCOMPANYYEARSUPERVISOR: `${HOST}${VERSION}admin/obamacare/totalSalesCompanyYearSupervisor`,

    TOTALSALESMONTH: `${HOST}${VERSION}admin/obamacare/totalSalesMonth`,
    TOTALSALESMONTHSUPERVISOR: `${HOST}${VERSION}admin/obamacare/totalSalesMonthSupervisor`,
    TOTALSALESMONTHNPN: `${HOST}${VERSION}admin/obamacare/totalSalesMonthNPN`,
    TOTALSALESAGENTSUSER: `${HOST}${VERSION}admin/obamacare/totalSalesAgentsUser`,
    TOTALSALESMONTHUSER: `${HOST}${VERSION}admin/obamacare/totalSalesMonthUser`,
    TOTALSALESMONTHLASTVSCURRENT: `${HOST}${VERSION}admin/obamacare/totalSalesMonthLastVsCurrent`,
    TOTALSALESMONTHLASTVSCURRENTSUPERVISOR: `${HOST}${VERSION}admin/obamacare/totalSalesMonthLastVsCurrentSupervisor`,
    TOTALSALESFORPLAN: `${HOST}${VERSION}admin/obamacare/totalSalesForPlan`,
    TOTALSALESFORPLANSUPERVISOR: `${HOST}${VERSION}admin/obamacare/totalSalesForPlanSupervisor`,
    LISTYEARS: `${HOST}${VERSION}admin/obamacare/listYears`,
    LISTGOALS: `${HOST}${VERSION}admin/obamacare/listGoals`,
    CREATEGOALS: `${HOST}${VERSION}admin/obamacare/createGoals`,
    EDITGOALS: `${HOST}${VERSION}admin/obamacare/editGoals`,
    CREATEGOALPREMIUMYEAR: `${HOST}${VERSION}admin/obamacare/createGoalPremiumYear`,
    SEARCHNOTEPAD: `${HOST}${VERSION}admin/obamacare/searchNotePad`,
    LISTNOTEPAD: `${HOST}${VERSION}admin/obamacare/listNotePad`,
    SAVENOTEPAD: `${HOST}${VERSION}admin/obamacare/saveNotePad`,
    SAVENOTECOLUMNS: `${HOST}${VERSION}admin/obamacare/saveNotecolumns`,
    SEARCHNOTECOLUMNS: `${HOST}${VERSION}admin/obamacare/searchNoteColumns`,
    SEARCHGOALPREMIUMYEAR: `${HOST}${VERSION}admin/obamacare/searchGoalPremiumYear`,
    LISTSALESSUPERVISOR: `${HOST}${VERSION}admin/obamacare/listSalesSupervisor`,
    LISTSALESNPN: `${HOST}${VERSION}admin/obamacare/listSalesNPN`,
    LISTSALESBACKOFFICE: `${HOST}${VERSION}admin/obamacare/listSalesBackOffice`,
    VERIFYNUMBERPLAN: `${HOST}${VERSION}admin/obamacare/verifyNumber`,
    EXPORTFILESXLXS: `${HOST}${VERSION}admin/obamacare/exportFileXLXS`,
    EXPORTFILESXLXSPROCESSOR: `${HOST}${VERSION}admin/obamacare/exportFileXLXSProcessor`,
    EXPORTFILESXLXSQUALITY: `${HOST}${VERSION}admin/obamacare/exportFileXLXSQuality`,
    DELETEFILESXLXS: `${HOST}${VERSION}admin/obamacare/deleteFileXLXS`,
    SEARCHSALESQUALITY: `${HOST}${VERSION}admin/obamacare/searchsalesquality`,
    SEARCHSALESMANAGED: `${HOST}${VERSION}admin/obamacare/searchsalesManaged`,
    SEARCHSALESQUALITYTRANSFER: `${HOST}${VERSION}admin/obamacare/searchsalesqualityTransfer`,
    SEARCHVERIFYMONTH: `${HOST}${VERSION}admin/obamacare/searchVerifyMonth`,
    INSERTVERIFYMONTH: `${HOST}${VERSION}admin/obamacare/insertVerifyMonth`,

    LISTPRENEWAPPOINTMENTSALES: `${HOST}${VERSION}admin/obamacare/listprenewappointmentsales`,
    LISTAPPOINTMENTSALESDATE: `${HOST}${VERSION}admin/obamacare/listappointmentsalesdate`,
    SEARCHAPPOINTMENTSALES: `${HOST}${VERSION}admin/obamacare/searchappointmentsales`,
    SEACRHSALES: `${HOST}${VERSION}admin/obamacare/searchsales`,
    UPDATEFORMSALES: `${HOST}${VERSION}admin/obamacare/updateformsales`,
    DELETESALES: `${HOST}${VERSION}admin/obamacare/deletesales`,
    UPDATESTATUSSALES: `${HOST}${VERSION}admin/obamacare/updateStatusSales`,
    CREATELOGCMSSALES: `${HOST}${VERSION}admin/obamacare/createLogCMSSales`,
    DELETESIGNATURECMS: `${HOST}${VERSION}admin/obamacare/deleteSignatureCMS`,

    // FORM SUPLEMENTAL
    UPLOADFORMSUPLEMENTAL: `${HOST}${VERSION}admin/obamacare/uploadformsuplemental`,
    LISTFORMSUPLEMENTAL: `${HOST}${VERSION}admin/obamacare/listformsuplemental`,
    UPDATEFORMSUPLEMENTAL: `${HOST}${VERSION}admin/obamacare/updateformsuplemental`,
    DELETESUPLEMENTAL: `${HOST}${VERSION}admin/obamacare/deletesuplemental`,

    // AGENCY
    LISTAGENCY: `${HOST}${VERSION}admin/agency/listagency`,
    CREATEAGENCY: `${HOST}${VERSION}admin/agency/createagency`,
    UPDATEAGENCY: `${HOST}${VERSION}admin/agency/updateagency`,

    REPORTSFILTER: `${HOST}${VERSION}admin/obamacare/reportsFilters`,
    REPORTSFILTERSUPERVISOR: `${HOST}${VERSION}admin/obamacare/reportsFiltersSupervisor`,
    EXPORTDATAEXCELFILTER: `${HOST}${VERSION}admin/obamacare/exportDataExcelFilters`,
    LISTSALESBACKOFFICEFILTER: `${HOST}${VERSION}admin/obamacare/listSalesBackOfficeFilter`,

    TRANSFERSALES: `${HOST}${VERSION}admin/obamacare/transferSales`,
    LISTHOSTTRANSFERS: `${HOST}${VERSION}admin/obamacare/listHostTransfers`,
    SEARCHSALESPREOTHER: `${HOST}${VERSION}admin/obamacare/searchsalespreOther`,
    CREATEMEMBERID: `${HOST}${VERSION}admin/obamacare/createMemberID`,
    SIGNATURESHERPA: `${HOST}${VERSION}admin/obamacare/SignatureSherpa`,

    EXPORTDATACMS: `${HOST}${VERSION}admin/obamacare/exportDataCMS`,
    VIEWDATACMS: `${HOST}${VERSION}admin/obamacare/viewDataCMS`,
    EXPORTDATASALE: `${HOST}${VERSION}admin/obamacare/exportDataSale`,
    VIEWLOGSTATUSSALES: `${HOST}${VERSION}admin/obamacare/viewLogStatusSales`,
    CHECKLISTQUALITY: `${HOST}${VERSION}admin/obamacare/checkListQuality`,
    SAVECHECKLISTQUALITY: `${HOST}${VERSION}admin/obamacare/saveCheckListQuality`,

    RETURNQUALITY: `${HOST}${VERSION}admin/obamacare/returnQuality`,

    UPDATESALESDATAOTHER: `${HOST}${VERSION}admin/obamacare/updatesalesDataOther`,//Actualizar la data de la venta transferida

    LISTROWSALEMONTH: `${HOST}${VERSION}admin/users/listRowSaleMonth`,
    LISTROWSALEMONTHUSER: `${HOST}${VERSION}admin/users/listRowSaleMonthUser`,
    CREATEROWSALEMONTH: `${HOST}${VERSION}admin/users/createRowSaleMonth`,
    DELETEROWSALEMONTH: `${HOST}${VERSION}admin/users/deleteRowSaleMonth`,

    SEARCHSALESCUSTOMERMONTHFILTER: `${HOST}${VERSION}admin/obamacare/searchsalesCustomerMonthFilter`,
    SEARCHNPNFORSTATE: `${HOST}${VERSION}admin/obamacare/searchNPNForState`,
    ADDOBSERVATIONSALE: `${HOST}${VERSION}admin/obamacare/addObservationSale`,
    TOTALSSNOPTIONS: `${HOST}${VERSION}admin/obamacare/totalSSNOptions`,
    TOTALPROCESSINGOPTIONS: `${HOST}${VERSION}admin/obamacare/totalProcessingOptions`,
    TOTALSTATUSFINISH: `${HOST}${VERSION}admin/obamacare/totalStatusFinish`,
    SAVESIGNATURE: `${HOST}${VERSION}admin/obamacare/saveSignature`,
    SAVEAPPLICANTSIGNATURE: `${HOST}${VERSION}admin/obamacare/saveApplicantSignature`,
    FILTERCONSULTOR: `${HOST}${VERSION}admin/obamacare/filterConsultor`,
    ADDOBSERVATIONSALE: `${HOST}${VERSION}admin/obamacare/addObservationSale`,
    STATUSCOMPLETE: `${HOST}${VERSION}admin/obamacare/statusComplete`,
    STATUSMANAGEMENT: `${HOST}${VERSION}admin/obamacare/statusManagement`,

    SAVEEMAIL: `${HOST}${VERSION}admin/email/saveEmail`,
    LISTEMAIL: `${HOST}${VERSION}admin/email/listEmail`,
    LISTFILTEREMAIL: `${HOST}${VERSION}admin/email/listFilterEmail`,
    LISTEMAILREPLY: `${HOST}${VERSION}admin/email/listEmailReply`,
    EMAILUNREAD: `${HOST}${VERSION}admin/email/emailUnread`,
    SEARCHTEMPLATEEMAIL: `${HOST}${VERSION}admin/email/searchTemplateEmail`,
    UPLOADFILEEMAILS: `${HOST}${VERSION}admin/email/uploadFilesEmails`,

    LISTNOTIFICATION: `${HOST}${VERSION}admin/users/listNotification`,
    LISTNOTIFICATIONLIMIT: `${HOST}${VERSION}admin/users/listNotificationLimit`,
    UPDATEVIEWNOTIFICATION: `${HOST}${VERSION}admin/users/updateViewNotification`,

    SEARCHPAYMONTH: `${HOST}${VERSION}admin/pay/searchPayMonth`,
    EXPORTPAYMONTH: `${HOST}${VERSION}admin/pay/exportPayMonth`,

    SAVELIFECHANGE: `${HOST}${VERSION}admin/obamacare/saveLifeChange`,
    SAVERESPONSELIFECHANGE: `${HOST}${VERSION}admin/obamacare/saveResponseLifeChange`,
    SEARCHSALESLIFECHANGE: `${HOST}${VERSION}admin/obamacare/searchsalesLifeChange`,

    SENDEMAILCMS: `${HOST}${VERSION}admin/obamacare/sendEmailCMS`,

    DELETENUMBERUPLOADAGENTS: `${HOST}${VERSION}admin/users/deleteNumberUploadAgents`,
    LISTTOTALSTATUSUPLOADAGENT: `${HOST}${VERSION}admin/users/listTotalStatusUploadAgent`,
    EXPORTUPLOADAGENTS: `${HOST}${VERSION}admin/users/exportUploadAgents`,

    SAVESTATUSCOMPLETE: `${HOST}${VERSION}admin/obamacare/saveStatusComplete`,
    TOTALCALLSAGENTS: `${HOST}${VERSION}admin/upload/totalCallsAgents`,

    SAVEHASAGENT: `${HOST}${VERSION}admin/obamacare/saveHasAgent`,
    SAVEAPPROVEDAGENT: `${HOST}${VERSION}admin/obamacare/saveApprovedAgent`,

    LISTLEADDISPOSITIONS: `${HOST}${VERSION}admin/appointment/listLeadDispositions`,
    SAVESTATUSLEADDISPOSITIONS: `${HOST}${VERSION}admin/appointment/saveStatusLeadDispositions`,
    LISTSTATUSLEADDISPOSITIONS: `${HOST}${VERSION}admin/appointment/listStatusLeadDispositions`,
    FILTERLEADDISPOSITIONS: `${HOST}${VERSION}admin/appointment/filterLeadDispositions`,
    FILTERTOTALLEADDISPOSITIONS: `${HOST}${VERSION}admin/appointment/filterTotalLeadDispositions`,
    FILTERTOTALSTATUSLEADDISPOSITIONS: `${HOST}${VERSION}admin/appointment/filterTotalStatusLeadDispositions`,
    EXPORTEXCELLEADS: `${HOST}${VERSION}admin/appointment/exportExcelLead`,
    UPLOADFILELEADS: `${HOST}${VERSION}admin/appointment/uploadFileLeads`,
    SAVECARDSENT: `${HOST}${VERSION}admin/obamacare/saveCardSent`,

    GETTOTALSALESHASAGENT: `${HOST}${VERSION}admin/obamacare/getTotalSalesHasAgent`,

    //INDICATORS HOME
    STATUSINDICATORSSALES: `${HOST}${VERSION}admin/indicators/statusIndicatorsSales`,
    METALINDICATORSSALES: `${HOST}${VERSION}admin/indicators/metalIndicatorsSales`,
    COMPANYSINDICATORSSALES: `${HOST}${VERSION}admin/indicators/companysIndicatorsSales`,
    SSNINDICATORSSALES: `${HOST}${VERSION}admin/indicators/ssnIndicatorsSales`,
    SSNNPNINDICATORSSALES: `${HOST}${VERSION}admin/indicators/ssnNPNIndicatorsSales`,
    COMPLETEANDTOTALINDICATORSSALES: `${HOST}${VERSION}admin/indicators/completeAndTotalIndicatorsSales`,

    CREATEMEMBERSUSCRIBER: `${HOST}${VERSION}admin/obamacare/createMemberSuscriber`,
    UPLOADVALIDATESALESSTATUSFINISH: `${HOST}${VERSION}admin/upload/uploadValidateSalesStatusFinish`,
}

export default APP_URL